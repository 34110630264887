import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/article-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The Hunt: Staying Safe In The Woods`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/8ef7d2d29fe8da4b3cddda01f0fe2faf/63a7e/the-hunt-1.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "68%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAempzBVIXMv/xAAbEAABBAMAAAAAAAAAAAAAAAACAQMREgAiMv/aAAgBAQABBQKijlkUWjsAlJxq3x//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8BjH//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAaEAACAgMAAAAAAAAAAAAAAAAAAQIREHHx/9oACAEBAAY/AlfCRZseP//EABkQAAMBAQEAAAAAAAAAAAAAAAABIRExQf/aAAgBAQABPyHG0S2jJKPq0wm76OTW3D1jr9RCH//aAAwDAQACAAMAAAAQ++//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPxAEf//EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8QeMp//8QAGxABAAMAAwEAAAAAAAAAAAAAAQARITFBcaH/2gAIAQEAAT8QHoWLXBV7DrFo+dwsgKaunqHtoFbhzjvIRdVQF92YoG2/Z//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the hunt 1",
            "title": "the hunt 1",
            "src": "/static/8ef7d2d29fe8da4b3cddda01f0fe2faf/63a7e/the-hunt-1.jpg",
            "srcSet": ["/static/8ef7d2d29fe8da4b3cddda01f0fe2faf/e07e9/the-hunt-1.jpg 200w", "/static/8ef7d2d29fe8da4b3cddda01f0fe2faf/066f9/the-hunt-1.jpg 400w", "/static/8ef7d2d29fe8da4b3cddda01f0fe2faf/63a7e/the-hunt-1.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`WILLIAMS, Ariz. — RVs, tents, ATVs are tucked into every nook and cranny along the Bill Williams Loop Road Dec. 8, the first day of the fall antlerless elk hunt in northern Arizona. At every camp site, campers in camouflage seem to be relaxing, many eating a late breakfast or lunch after waking early and heading out to scout for signs of elk. The smell of bacon and eggs is overwhelming.`}</p>
    <p>{`Arizona Game and Fish Department (AZGFD) officer William Lemon pulls on to the Bill Williams Loop Road, otherwise known as FR 108, where he plans to spend the next six hours checking hunting permits along the 30-mile road.`}</p>
    <p>{`All of the campers Lemon contacts are friendly, rising from their chairs when they see the marked patrol truck, some offering to share their breakfast, others asking where to find the elk. Lemon said he uses the contacts to not only check for permits, but to answer questions and educate hunters about regulations.`}</p>
    <p>{`He said there are 500 cow elk tags issued for Unit 8 of the Williams district. A large portion of the hunters camp on FR 108. “I believe that during the bull hunt, half of the hunters were in this area,” Lemon said referring to the recently closed hunt.`}</p>
    <p>{`As Lemon works his way down the road, he doesn’t travel more than a few hundred yards before encountering another group. The groups are tucked into small pull-offs and side roads in the ponderosa pines. Each group has anywhere from two to 10 campers. They are all obviously well equipped with rifles, handguns and large hunting knives. For the unseasoned observer, that’s a lot of weapons. In this environment, with so many people hunting with weapons in close quarters, some may wonder how it is possible to stay safe.`}</p>
    <h2>{`Hunting related accidents`}</h2>
    <p>{`According to the International Hunter Education Association, there are around 1,000 firearms related hunting accidents each year across the nation. Of those, 100 prove to be fatal every year. Fortunately in Arizona, hunting accidents are fairly low. According to AZGFD spokeswoman Shelly Shepherd, Arizona has had 26 reported hunting accidents since 2007, including three fatalities. However, this fall, an Arizona resident was involved in a hunting accident in Colorado.`}</p>
    <p>{`Phoenix firefighter David Barter was shot and killed in a hunting accident in Colorado Nov. 16. According to the Saguache County Sheriff, Barter was shot by a juvenile member of his own hunting party who was 400 yards away. Throughout the country, fatal hunting accident reports have become fairly regular news. On Nov. 13, a 15-year-old Alabama girl was shot during a youth hunt. She died from her wound.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/783a1eff6885028a39a8128a1a0e778c/63a7e/the-hunt-2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "72%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAgAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAgAB/9oADAMBAAIQAxAAAAHNBLFUr//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMSERMi/9oACAEBAAEFAnPw64hJ1LVNaH//xAAVEQEBAAAAAAAAAAAAAAAAAAAQEf/aAAgBAwEBPwGn/8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQIBAT8Bh//EABkQAQACAwAAAAAAAAAAAAAAAAEAEAIhUf/aAAgBAQAGPwIJysCOq//EABsQAAMAAwEBAAAAAAAAAAAAAAABIRExYXGR/9oACAEBAAE/IYBvvSnl/Q0paawxokyns8H/2gAMAwEAAgADAAAAEJQP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEh/9oACAEDAQE/EMK//8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPxDvCDC//8QAHBABAQEBAAIDAAAAAAAAAAAAAREhADFRYbHh/9oACAEBAAE/EN1qlDy1Q5SiVur5PziS6X2zhBlWJ8r983hURRzhCRmQ7//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the hunt 2",
            "title": "the hunt 2",
            "src": "/static/783a1eff6885028a39a8128a1a0e778c/63a7e/the-hunt-2.jpg",
            "srcSet": ["/static/783a1eff6885028a39a8128a1a0e778c/e07e9/the-hunt-2.jpg 200w", "/static/783a1eff6885028a39a8128a1a0e778c/066f9/the-hunt-2.jpg 400w", "/static/783a1eff6885028a39a8128a1a0e778c/63a7e/the-hunt-2.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Investigators believe the girl was killed when she was accidentally shot while descending a tree stand. Reports said a hunter handed her a rifle, which discharged and struck her.
On Nov. 22, a 43-year-old woman was killed when a hunter accidentally shot her while she walked her dogs in Sherman, New York. Deputies say the hunter believed he saw a deer in a field and fired a shot. The woman died on the way to the hospital.
On Dec. 3, a duck hunter in Morganton, North Carolina, was shot in the shoulder by a high powered rifle as he was leaving the area. Investigators believe a deer hunter accidently shot the victim when he mistook the man for a deer.`}</p>
    <p>{`On Dec. 5, a 60-year-old man in Moscow, Washington was shot in the buttocks by a .50 caliber muzzleloading rifle. Deputies determined the man’s 72-year-old hunting partner thought he was firing at an elk when he accidentally shot his friend.`}</p>
    <h2>{`How to be safe`}</h2>
    <p>{`According to the AZGFD, the four main causes of hunting accidents are mistaking another person for game, pointing the muzzle in an unsafe direction, having a lack of control and practice, and mechanical failures. In Arizona, game and fish officials try to stem these problems with hunter education courses. Shepherd says the department recommends every hunter take a hunter safety course. The course is required for youth hunters under the age of 14. “We encourage the course for everyone who wants to hunt,” Shepherd said. “It’s a good starting point for somebody that is new.”`}</p>
    <p>{`Shepherd said the course covers firearms and ammunition, hunter safety tips, hunting techniques, wildlife identification, first-aid and more.
“It’s crucial to know your target and beyond,” Shepherd said.
Shepherd said it is important for people to be familiar with their weapons.
“We get people who purchase a rifle and never sight it in, they just pull it out of the box,” she said. “It is critical that you get out to a shooting range or safe place and practice with that rifle and know you are proficient with it.”
She said it is also important to be aware of surroundings.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/51b11d49af1f6f5ac03ac1abe8bb8b27/63a7e/the-hunt-3.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "69%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAAUD/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAH/2gAMAwEAAhADEAAAAWk7AuISv//EABoQAAIDAQEAAAAAAAAAAAAAAAECAAMSERP/2gAIAQEAAQUCazaqyhdJLOg7InsZ/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AWR//8QAGhAAAwADAQAAAAAAAAAAAAAAAAERAhAhMf/aAAgBAQAGPwLFCTVZ4Slfdf/EABoQAQACAwEAAAAAAAAAAAAAAAEAESExUUH/2gAIAQEAAT8hVw0elZhMg21Hnb4TilRVsGJyxP/aAAwDAQACAAMAAAAQh8//xAAXEQEBAQEAAAAAAAAAAAAAAAABABFB/9oACAEDAQE/EHZXl//EABYRAQEBAAAAAAAAAAAAAAAAAAEAQf/aAAgBAgEBPxADYj//xAAbEAEAAwEAAwAAAAAAAAAAAAABABEhMUFRYf/aAAgBAQABPxCpxpsVZj64QPGaDvyApqixogJmCleLlqgHWWSoAICgAyf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the hunt 3",
            "title": "the hunt 3",
            "src": "/static/51b11d49af1f6f5ac03ac1abe8bb8b27/63a7e/the-hunt-3.jpg",
            "srcSet": ["/static/51b11d49af1f6f5ac03ac1abe8bb8b27/e07e9/the-hunt-3.jpg 200w", "/static/51b11d49af1f6f5ac03ac1abe8bb8b27/066f9/the-hunt-3.jpg 400w", "/static/51b11d49af1f6f5ac03ac1abe8bb8b27/63a7e/the-hunt-3.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`“We’ve got a lot of cases, especially during archery season, where they are taking shots much further out than their equipment can handle.”
Shepherd said keeping safe while hunting is not limited to firearms. She said it is imperative to be prepared for the physical requirements of harvesting an animal.
“It’s important to have a game plan,” she said. “Literally the hard work starts when the animal is down. If you hiked in a mile do you have a plan to pack out that animal?”
Lemon said earlier this year he assisted a hunter who shot an elk in the bottom of a canyon near Sycamore Falls.
“We had to get a rock climber to help him haul the animal out of the canyon,” he said.`}</p>
    <h2>{`Hunter education in the field`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "670px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/d626b4bc6d930062cd2d6dc6c81bfa6c/63a7e/the-hunt-4.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.00000000000001%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAPABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQCBf/EABUBAQEAAAAAAAAAAAAAAAAAAAEC/9oADAMBAAIQAxAAAAGrnXRlaDP/xAAbEAEAAgIDAAAAAAAAAAAAAAACAAEDERITIf/aAAgBAQABBQLWxySlexUlAFOrNc//xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAIAQMBAT8Bia//xAAXEQADAQAAAAAAAAAAAAAAAAAAAREC/9oACAECAQE/Aai5P//EABwQAAICAgMAAAAAAAAAAAAAAAAhAQIRMVFhcf/aAAgBAQAGPwJrs2hiUcFox4aqf//EABsQAAICAwEAAAAAAAAAAAAAAAERACExQVGB/9oACAEBAAE/IQIChodXuO1RQiC1cNDAC6iJLXlEwR7qf//aAAwDAQACAAMAAAAQcx//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARH/2gAIAQMBAT8Q3FX/xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAgEBPxAlf//EABwQAQEAAwADAQAAAAAAAAAAAAERACExUYHB8P/aAAgBAQABPxDf3JqH8THliug55cjkJCOuAY9rfxAe7HAbgIRt+ZAMBOi5/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "the hunt 4",
            "title": "the hunt 4",
            "src": "/static/d626b4bc6d930062cd2d6dc6c81bfa6c/63a7e/the-hunt-4.jpg",
            "srcSet": ["/static/d626b4bc6d930062cd2d6dc6c81bfa6c/e07e9/the-hunt-4.jpg 200w", "/static/d626b4bc6d930062cd2d6dc6c81bfa6c/066f9/the-hunt-4.jpg 400w", "/static/d626b4bc6d930062cd2d6dc6c81bfa6c/63a7e/the-hunt-4.jpg 670w"],
            "sizes": "(max-width: 670px) 100vw, 670px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`As Lemon continued his patrol around the Bill Williams loop road, he contacted a group of hunters target shooting.
The group, who stopped shooting to greet Lemon, included two young hunters. The family stated they were from Kingman and had hunted in Williams many times over the past few years.
The older youth hunter, Joseph Powell, said he was 18 years old now and took an AZGFD hunter safety course when he was 14.
“I think it was beneficial,” he said. “You think you know a lot and then you go to the class and you learn a lot more.”
Powell’s father, Brian Powell, said he was so impressed with what his son learned, that he decided to volunteer and become a hunter education instructor.
“A friend of Joseph’s came over and I watched how they handled their pellet guns when no one was watching,” he said. “To see what they knew and to see it really sink in was really neat. I realized how much they retained from the class. That’s when I signed up to volunteer.”
Local hunter Billy Martin was at the next camp Lemon contacted on the Bill Williams loop Road.
Martin said he has hunted in Williams for years. When asked how his group stays safe hunting, he said they like to break into groups of two and they each wear hunter orange caps.`}</p>
    <p>{`“It’s a lot easier to keep track of your partner,” he said. “We’re very conscientious about watching for other folks. I like to be away from other people.”
Shepherd said there are several factors for the low numbers of hunting related accidents in Arizona when compared to other states. She said many states, such as those in the Midwest, have higher numbers of hunters and larger bag limits than Arizona.
“I can’t say it’s all because of our hunter safety courses,” she said. “But they help.”
More information about hunter education courses is available at `}<a parentName="p" {...{
        "href": "http://www.hunter-ed.com/arizona"
      }}>{`www.hunter-ed.com/arizona`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      